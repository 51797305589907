import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Activate: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Function to get query parameters from the URL
    const getQueryParams = () => {
      return location.search.substring(1); // Get the query string without '?'
    };

    // Get the query parameters
    const queryParams = getQueryParams();

    // Construct the deep link and fallback URL
    const appLink = `screengallery://Activate?${queryParams}`;
    const fallbackLink = `https://screengallery.com/WorkFlow24`;

    // Try to open the app via deep link
    window.location.href = appLink;

    // If the app is not installed, redirect to the fallback link
    const fallbackTimeout = setTimeout(() => {
      window.location.href = fallbackLink;
    }, 2000); // 2-second timeout to allow app detection

    // Clean up the timeout when the component unmounts
    return () => clearTimeout(fallbackTimeout);
  }, [location]);

  return <p>Redirecting...</p>;
};

export default Activate;
