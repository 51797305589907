// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

// Add other Firebase services you need, for example:
// import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDc_HiCCF8JCHu8CQMm3mrcXBjoo3GWnSo",
  authDomain: "screengallery-dev.firebaseapp.com",
  databaseURL: "https://screengallery-dev-default-rtdb.firebaseio.com",
  projectId: "screengallery-dev",
  storageBucket: "screengallery-dev.appspot.com",
  messagingSenderId: "290202162989",
  appId: "1:290202162989:web:7655a2562a6ddb0c24a5a4",
  measurementId: "G-QKNFTJ2BLB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app);

// Add other services initialization, for example:
// const storage = getStorage(app);
// const analytics = getAnalytics(app);

export { auth, db, database };
