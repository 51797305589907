// src/components/FeedbackForm.tsx
import React, { useState } from "react";
import { ref, push } from "firebase/database";
import { database } from "src/firebase";

const FeedbackForm: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");

  const [errors, setErrors] = useState<{ email?: string; mobile?: string }>({});

  // Simple validation regex patterns
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^[0-9]{10}$/; // Assuming a 10-digit phone number format

  const validateForm = () => {
    let formErrors: { email?: string; mobile?: string } = {};
    if (!emailRegex.test(email)) {
      formErrors.email = "Please enter a valid email address.";
    }
    if (!mobileRegex.test(mobile)) {
      formErrors.mobile = "Please enter a valid 10-digit mobile number.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // True if no errors
  };

  const submitFeedback = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate form before submitting
    if (!validateForm()) return;

    const feedbackData = {
      name,
      email,
      mobile,
      feedback,
      timestamp: new Date().toUTCString(),
    };

    push(ref(database, "feedback/"), feedbackData)
      .then(() => {
        alert("Feedback submitted successfully!");
        setName("");
        setEmail("");
        setMobile("");
        setFeedback("");
        setErrors({});
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to submit feedback.");
      });
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100 p-6">
      <form
        onSubmit={submitFeedback}
        className="max-w-xl w-full mx-auto bg-white p-6 rounded-lg shadow-md"
      >
        <div className="flex justify-center">
          <img
            src="/images/splash_image.png"
            alt="Splash"
            className="w-48 h-24 object-contain"
          />
        </div>

        <h2 className="text-2xl font-bold mb-6 text-center">Feedback Form</h2>

        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 mb-2">
            Name:
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 mb-2">
            Email:
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={`w-full px-4 py-2 border ${
              errors.email ? "border-red-500" : "border-gray-300"
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
          />
          {errors.email && (
            <p className="text-red-500 text-sm">{errors.email}</p>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="mobile" className="block text-gray-700 mb-2">
            Mobile Number:
          </label>
          <input
            id="mobile"
            type="tel"
            value={mobile}
            max={10}
            onChange={(e) => setMobile(e.target.value)}
            required
            className={`w-full px-4 py-2 border ${
              errors.mobile ? "border-red-500" : "border-gray-300"
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
          />
          {errors.mobile && (
            <p className="text-red-500 text-sm">{errors.mobile}</p>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="feedback" className="block text-gray-700 mb-2">
            Feedback:
          </label>
          <textarea
            id="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
            rows={5}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Submit Feedback
        </button>
      </form>
    </div>
  );
};

export default FeedbackForm;
