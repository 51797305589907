import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Activate from "./components/Activate";
import WorkFlow24 from "./components/WorkFlow24";
import FeedbackForm from "./components/FeedbackForm";

const Home: React.FC = () => {
  return (
    <div
      className="flex justify-center items-center h-screen bg-[#385363] bg-cover bg-center"
      style={{ backgroundImage: "url('/images/bg.png')" }}
    >
      <img src="/images/logo.png" alt="App Logo" className="max-w-xs" />
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Activate" element={<Activate />} />
        <Route path="/WorkFlow24" element={<WorkFlow24 />} />
        <Route path="/Feedback" element={<FeedbackForm />} />
      </Routes>
    </Router>
  );
};

export default App;
